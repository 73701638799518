import { InjectionToken } from '@angular/core';

export const LocalStorageKeysTypes = ['uiTheme', 'i18n'];
export const LOCAL_STORAGE_KEYS_TOKEN: InjectionToken<string[]> = new InjectionToken<string[]>('LocalStorageKeysToken');
export const LOCAL_STORAGE_MANAGEMENT_KEY = 'LocalStorageManagement';

export const isLocalStorageKey =
  (allowedLocalStorageKeys: string[]) =>
  (key: string): boolean => {
    return allowedLocalStorageKeys.includes(key);
  };

export type LocalStorageState = { uiTheme: string | null; i18n: string | null; error: Error | null };

export const initialLocalStorageState: LocalStorageState = {
  uiTheme: null,
  i18n: null,
  error: null,
};
