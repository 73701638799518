import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { catchError, filter, of, take, tap } from 'rxjs';

import { environment } from '@env';
import {
  ApiCoreModule,
  ApiCoreService,
  HTTP_BASE_URL,
} from '@qtek/standalone/core-libs/api-core';
import { LocalStorageManagementModule } from '@qtek/standalone/core-libs/local-storage-management';
import { WEBSOCKET_BASE_URL } from '@qtek/standalone/core-libs/websockets-core';
import { MetaCoreModule } from '@qtek/standalone/libs/meta-core';
import { SnackBarCoreModule } from '@qtek/standalone/libs/snack-bar-core';
import { DebugTranslationsDI } from '@qtek/standalone/libs/translation-core';

import { appRoutes } from './app.routes';

/**
 * IMPORTANT: angular compiler do not support some es6+ features, and will break on AoT builds.
 *
 * - DO NOT USE DESTRUCTURING ASSIGNMENT
 * - DO NOT USE TEMPLATE STRINGS
 */

//  HTTP

const rest = environment.rest;
export const HTTP_BASE_URL_VALUE =
  rest.protocol +
  '://' +
  rest.hostname +
  (rest.port ? ':' + rest.port : '') +
  (rest.path ? rest.path : '');

// WS
// export const WEBSOCKET_BASE_URL = new InjectionToken<string>('WEBSOCKET_BASE_URL');

const websocket = environment.websocket;

export const WEBSOCKET_BASE_URL_VALUE = websocket
  ? websocket.protocol +
    '://' +
    websocket.hostname +
    (websocket.port ? ':' + websocket.port : '') +
    (websocket.path ? websocket.path : '')
  : '';

export function appInitializer(apiCoreService: ApiCoreService) {
  return () =>
    new Promise((resolve, reject) => {
      apiCoreService
        .getUserIsLogged<{
          rel: { email: string };
          guiDomain: string;
          auth: boolean;
        }>(HTTP_BASE_URL_VALUE)
        .pipe(
          tap(({ res }) => {
            const paramsFromBase64 = atob(
              window.location.search
                .replace('?', '')
                .replace(/-/g, '+')
                .replace(/_/g, '/')
                .replace(/\=+$/m, '') ?? ''
            );

            const arrayParamsWithValues = paramsFromBase64.split('&');
            const paramsValues = arrayParamsWithValues.map(val => {
              const index = val.indexOf('=');
              return {
                key: val.slice(0, index),
                value: val.slice(index + 1),
              };
            });

            const returnUrl = paramsValues.find(
              el => el.key === 'returnUrl'
            )?.value;
            const email = paramsValues.find(el => el.key === 'email')?.value;

            if (
              (email === res?.rel.email || !email) &&
              res.auth &&
              !!res.guiDomain
            ) {
              window.location.href = `${res.guiDomain}/${
                returnUrl ? returnUrl : ''
              }`;
              reject(false);
            } else if (res.auth) {
              apiCoreService.logout().pipe(take(1)).subscribe();
            }

            resolve(res.auth);
          }),
          catchError(error => {
            return of(false);
          }),
          filter(isLogged => !isLogged),
          take(1),
          tap(result => resolve(result))
        )
        .subscribe();
    });
}

const debugTranslation = environment.debugTranslations;

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideStore(),
    provideAnimations(),
    provideStoreDevtools(),
    importProvidersFrom(ApiCoreModule),
    importProvidersFrom(MetaCoreModule),
    importProvidersFrom(SnackBarCoreModule),
    importProvidersFrom(LocalStorageManagementModule),
    {
      provide: HTTP_BASE_URL,
      useValue: HTTP_BASE_URL_VALUE,
    },
    {
      provide: WEBSOCKET_BASE_URL,
      useValue: WEBSOCKET_BASE_URL_VALUE,
    },
    {
      provide: DebugTranslationsDI,
      useValue: debugTranslation ?? false,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [ApiCoreService],
      multi: true,
    },
  ],
};
