import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ThemeManagementActions = createActionGroup({
  source: 'ThemeManagementActions',
  events: {
    'Set Theme': props<{ theme: string }>(),
    'Set Theme Success': props<{ theme: string }>(),
    'Set Theme Failure': emptyProps,
    'Synchronize Theme': props<{ theme: string }>(),
  },
});
