import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

import { Message } from '@qtek/standalone/shared/models';
export interface WSAlertDialogInput {
  message: Message;
  title?: string;
  closeButton?: string;
  details?: Message[];
}

export const wsAlertDialogConfig: MatDialogConfig = {
  width: '600px',
  autoFocus: false,
};

@Component({
  selector: 'qt-ws-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class WSAlertDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: WSAlertDialogInput) {
    this.data = {
      closeButton: 'CMD_DISMISS',
      ...this.data,
    };
  }
}
