/** DO NOT EDIT THIS FILE! */
import { Version } from '@angular/core';
import { Bundle } from '@qtek/standalone/shared/models';

export const VERSION = new Version('1.22.19');

export const BUNDLE: Bundle = {
  date: 1725745007419,
  commitSha: '7ddc41aa562415e35cc215f82766b64fb2954a99',
  version: VERSION,
};
