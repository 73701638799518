import { UserModel } from '@qtek/standalone/shared/models';

export interface AuthUserState {
  user: UserModel | null;
}

export const initialAuthUserState: AuthUserState = {
  user: null,
};

export const AUTH_USER_KEY = 'AuthUserKey';
