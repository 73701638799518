import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PortalCoreService } from '@qtek/standalone/shared/services';

@Directive({
  selector: '[qtPortalTemplate]',
  standalone: true,
})
export class PortalTemplateDirective implements AfterViewInit, OnDestroy {
  @Input('qtPortalTemplate') outletName: string;

  constructor(
    private portalCoreService: PortalCoreService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    const portal = new TemplatePortal(this.templateRef, this.viewContainerRef);
    this.portalCoreService.attachPortal(portal, this.outletName);
  }

  ngOnDestroy() {
    this.portalCoreService.detachPortal(this.outletName);
  }
}
