import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LocalStorageActions = createActionGroup({
  source: 'localStorageActions',
  events: {
    'Refresh Local Storage': emptyProps(),
    'Refresh Local Storage Success': props<{
      records: {
        key: string;
        value: string;
      }[];
    }>(),
    'Refresh Local Storage By Key': props<{ key: string }>(),
    'Save To Local Storage': props<{
      records: {
        key: string;
        value: string;
      }[];
    }>(),
    'Save To Local Storage By Key': props<{ key: string; value: string }>(),
    'Delete Local Storage By Key': props<{ key: string }>(),
    'Clear Local Storage': emptyProps(),
    'Clear Local Storage Success': props<{ keys: string[] }>(),
    'Access Error': (error: Error) => ({ error }),
  },
});
