import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { ValidationErrors } from '@qtek/standalone/shared/models';
import * as patterns from './patterns';

export function getValuesFromGroup(group: UntypedFormGroup, fields: string[]) {
  return fields.map(name => {
    const control = group.get([name]);

    if (!control) {
      throw new Error(`[qt] Could not find "${name}" control within FormGroup`);
    }

    return control.value;
  });
}

export class CustomValidators {
  static mobilePhoneOrEmail(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const mobilePhoneUSError = patterns.MOBILE_PHONE_US.test(control.value);
    const emailError = patterns.EMAIL.test(control.value);
    const mobilePhoneIntError = patterns.MOBILE_PHONE_INTERNATIONAL.test(
      control.value
    );

    if (mobilePhoneUSError || emailError || mobilePhoneIntError) {
      return null;
    }

    return { emailOrPhone: true };
  }

  static mobilePhone(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      if (!control.parent.get(controlName)) {
        throw new TypeError(
          `[qt] Form Control "${controlName}" does not exists.`
        );
      }

      const country = control.parent.get(controlName).value;
      return this.checkmobilePhoneByCountry(country, control);
    };
  }

  static checkmobilePhoneByCountry(
    countryId: string,
    control: AbstractControl
  ): ValidationErrors | null {
    switch (countryId) {
      case 'US':
        return patterns.MOBILE_PHONE_US.test(control.value)
          ? null
          : {
              mobilePhone: true,
            };
      case 'PL':
        return patterns.MOBILE_PHONE_PL.test(control.value)
          ? null
          : {
              mobilePhone: true,
            };
      default:
        return this.internationalMobilePhoneOptional(control);
    }
  }

  static password(control: AbstractControl): ValidationErrors | null {
    return patterns.PASSWORD.test(control.value)
      ? null
      : {
          password: true,
        };
  }

  static address(control: AbstractControl): ValidationErrors | null {
    return control.value && control.value.id
      ? null
      : {
          address: true,
        };
  }

  static email(control: AbstractControl): ValidationErrors | null {
    return patterns.EMAIL.test(control.value)
      ? null
      : {
          email: true,
        };
  }

  static taxId(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return patterns.TAXID.test(control.value)
      ? null
      : {
          taxId: true,
        };
  }

  static zipCode(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return patterns.ZIPCODE.test(control.value)
      ? null
      : {
          zipCode: true,
        };
  }

  static match(...fields: string[]) {
    return (group: UntypedFormGroup) => {
      const values = getValuesFromGroup(group, fields);

      return values[0] === values[1]
        ? null
        : {
            match: true,
          };
    };
  }

  static notMatch(...fields: string[]) {
    return (group: UntypedFormGroup) => {
      const values = getValuesFromGroup(group, fields);

      return values[0] !== values[1]
        ? null
        : {
            notMatch: true,
          };
    };
  }

  static atLeastOne(group: UntypedFormGroup): ValidationErrors {
    return Object.values(group.value).filter(Boolean).length
      ? null
      : {
          atLeastOne: true,
        };
  }

  static integer(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    return typeof value === 'number' &&
      Number.isFinite(value) &&
      Math.floor(value) === value
      ? null
      : {
          integer: true,
        };
  }

  static checkbox(control: AbstractControl): ValidationErrors | null {
    return control.value
      ? null
      : {
          checkbox: true,
        };
  }

  static addressGroup(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      if (!control.parent.get(controlName)) {
        throw new TypeError(
          `[qt] Form Control "${controlName}" does not exists.`
        );
      }

      const selectedControl = control.parent.get(controlName);
      return this.address(selectedControl);
    };
  }

  static safeUrlString(control: AbstractControl): ValidationErrors | null {
    return new RegExp(/^[a-zA-Z0-9_-]*$/).test(control.value)
      ? null
      : {
          invalidValue: true,
        };
  }

  static internationalMobilePhone(
    control: AbstractControl
  ): ValidationErrors | null {
    return patterns.MOBILE_PHONE_INTERNATIONAL.test(control.value)
      ? null
      : {
          mobilePhone: true,
        };
  }

  static internationalMobilePhoneOptional(
    control: AbstractControl
  ): ValidationErrors | null {
    return patterns.MOBILE_PHONE_INTERNATIONAL.test(control.value) ||
      !control.value
      ? null
      : {
          mobilePhone: true,
        };
  }

  static checkEmailMatch(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      if (!control.parent.get(controlName)) {
        throw new TypeError(
          `[qt] Form Control "${controlName}" does not exists.`
        );
      }

      const emailValue: any = control.parent.get(controlName).value;

      return control.value === emailValue
        ? null
        : {
            emailNotMatch: true,
          };
    };
  }

  static checkEmailNotMatch(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      if (!control.parent.get(controlName)) {
        throw new TypeError(
          `[qt] Form Control "${controlName}" does not exists.`
        );
      }

      const emailValue: any = control.parent.get(controlName).value;

      return control.value !== emailValue
        ? null
        : {
            emailMatch: true,
          };
    };
  }

  static urlAddress(control: AbstractControl): ValidationErrors | null {
    return patterns.URL.test(control.value) || !control.value
      ? null
      : {
          wrongUrl: true,
        };
  }
}
