<h1
  matDialogTitle
  *ngIf="data.title"
>{{ data.title | translate }}</h1>
<mat-dialog-content class="mb-3">
  <p class="my-0 mat-body">
    {{ data.message.expression | translate: data.message.params }}
  </p>
  <ng-container *ngIf="data.details?.length">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'GNR_DETAILS' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list dense>
        <ng-container *ngFor="let message of data.details; last as isLast">
          <mat-list-item>{{ message.expression | translate: message.params }}</mat-list-item>
          <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>
      </mat-list>
    </mat-expansion-panel>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions
  fxLayout
  fxLayoutAlign="end"
>
  <button
    mat-button
    matDialogClose
  >{{ data.closeButton | translate }}</button>
</mat-dialog-actions>