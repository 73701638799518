import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MfaLoginActions = createActionGroup({
  source: 'Mfa Login',
  events: {
    'Set Email': props<{ email: string }>(),
    'Set Email Editable': props<{ isEditable: boolean }>(),
    'Set First Name': props<{ fn: string }>(),
    'Set Password': props<{ passwd: string }>(),
    'Set MfaCode': props<{ mfaCode: string; saveDevice: boolean }>(),
    'Reset Snack Bar Messages': emptyProps(),
    'Set Snack Bar Error Message': props<{
      message: string;
      params?: string[];
    }>(),
    'Set Snack Bar Normal Message': props<{
      message: string;
      params?: string[];
    }>(),
    'Set Return Url': props<{ returnUrl: string }>(),
    'Set Validation Error': props<{ message: string; params?: string[] }>(),
    'Standard Login': props<{ email: string; passwd: string }>(),
    'Standard Login Success': emptyProps(),
    'Standard Login Error': props<{ error: Error }>(),
    'Standard Register': props<{
      email: string;
      passwd: string;
      lng: string;
      gdprFlag: boolean;
      tkn: string;
      tzGuess?: string;
    }>(),
    'Required Mfa': emptyProps(),
    'Mfa Login': props<{
      email: string;
      passwd: string;
      mfa_code: string;
      saveDevice: boolean;
    }>(),
    'Mfa Login Success': emptyProps(),
    'Mfa Login Error': props<{ error: Error }>(),
    'Reset Password': props<{ email: string }>(),
    'Reset Password Confirm': props<{
      pass1: string;
      pass2: string;
      rstToken: string;
      email: string;
    }>(),
    'Redirect App': emptyProps(),
  },
});
