import { NgModule } from '@angular/core';
import { ApiCoreModule } from '@qtek/standalone/core-libs/api-core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { MetaCoreEffects } from './store/meta-core.effects';
import { MetaCoreFeature } from './store/meta-core.reducer';

@NgModule({
  imports: [ApiCoreModule],
  providers: [provideEffects(MetaCoreEffects), provideState(MetaCoreFeature)],
})
export class MetaCoreModule {}
