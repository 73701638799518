import { Inject, Injectable } from '@angular/core';
import { isLocalStorageKey, LOCAL_STORAGE_KEYS_TOKEN } from '../models/local-storage-store.models';
import { Store } from '@ngrx/store';
import { asapScheduler, fromEvent, map, take, tap } from 'rxjs';
import { LocalStorageActions } from '../store/local-storage.actions';
import { LocalStorageManagementFeature } from '../store/local-storage.reducers';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageManagementService {
  private readonly changeLocalStorageContentSubscription = fromEvent<StorageEvent>(window, 'storage')
    .pipe(
      map((event) => {
        if (isLocalStorageKey(this.localStorageKeys)(event.key)) {
          this.store.dispatch(LocalStorageActions.refreshLocalStorageByKey({ key: event.key }));
        }
      })
    )
    .subscribe();

  private readonly initialLocalStorageContentSubscription = this.store
    .select(LocalStorageManagementFeature.selectLocalStorageManagementState)
    .pipe(
      take(1),
      tap(() => asapScheduler.schedule(() => this.store.dispatch(LocalStorageActions.refreshLocalStorage())))
    )
    .subscribe();

  constructor(@Inject(LOCAL_STORAGE_KEYS_TOKEN) private localStorageKeys: string[], private store: Store) {}
}
