import { createFeature, createReducer, on } from '@ngrx/store';
import { initialLocalStorageState, LOCAL_STORAGE_MANAGEMENT_KEY } from '../models/local-storage-store.models';
import { LocalStorageActions } from './local-storage.actions';

export const LocalStorageReducer = createReducer(
  initialLocalStorageState,
  on(LocalStorageActions.refreshLocalStorageSuccess, (state, { records }) => ({
    ...state,
    ...records.reduce((prevValue, record) => {
      return {
        ...prevValue,
        [record.key]: record.value,
      };
    }, {}),
  })),
  on(LocalStorageActions.clearLocalStorageSuccess, (state, { keys }) => ({
    ...state,
    ...keys.reduce((prevValue, key) => {
      return {
        ...prevValue,
        [key]: null,
      };
    }, {}),
  })),
  on(LocalStorageActions.accessError, (state, { error }) => ({
    ...state,
    error,
  }))
);

export const LocalStorageManagementFeature = createFeature({
  name: LOCAL_STORAGE_MANAGEMENT_KEY,
  reducer: LocalStorageReducer,
});
