import { Portal, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { ComponentRef, EmbeddedViewRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PortalCoreService {
  private portalOutlet: Map<string, PortalOutlet> = new Map<
    string,
    PortalOutlet
  >();
  private portalContext: Map<string, any> = new Map<string, any>();
  private pendingPortals: Map<string, Portal<any>> = new Map<
    string,
    Portal<any>
  >();

  registerPortalOutlet(
    portalOutlet: PortalOutlet,
    portalId: string,
    context?: any
  ): void {
    this.portalOutlet.set(portalId, portalOutlet);
    this.portalContext.set(portalId, context);

    const pendingPortal = this.pendingPortals.get(portalId);
    if (pendingPortal) {
      if (pendingPortal instanceof TemplatePortal) {
        pendingPortal.context = context;
      }
      portalOutlet.attach(pendingPortal);
      this.pendingPortals.delete(portalId); // Remove from pending after attaching
    }
  }

  unregisterPortalOutlet(portalId: string): void {
    const outlet = this.portalOutlet.get(portalId);
    if (outlet) {
      outlet.detach();
      this.portalOutlet.delete(portalId);
      this.portalContext.delete(portalId);
      this.pendingPortals.delete(portalId);
    }
  }

  attachPortal(
    portal: Portal<any>,
    portalId: string
  ): EmbeddedViewRef<any> | ComponentRef<any> | null {
    const outlet = this.portalOutlet.get(portalId);
    if (outlet) {
      if (portal instanceof TemplatePortal) {
        portal.context = this.portalContext.get(portalId);
      }
      return outlet.attach(portal);
    } else {
      if (portal instanceof TemplatePortal) {
        portal.context = this.portalContext.get(portalId);
      }
      this.pendingPortals.set(portalId, portal);
      return null;
    }
  }

  detachPortal(portalId: string): void {
    this.portalOutlet.get(portalId)?.detach();
  }
}
