type Language = 'en_US' | 'pl_PL' | 'es_US';

interface TermsAndPrivacy {
  terms: string;
  privacy: string;
}

export function getLinks(language: Language | string): TermsAndPrivacy {
  switch (language) {
    // case 'pl_PL':
    // case 'pl':
    //   return {
    //     terms: 'https://calio.me/web/warunki-korzystania/',
    //     privacy: 'https://calio.me/web/polityka-prywatnosci/',
    //   };
    // case 'es_US':
    // case 'es':
    //   return {
    //     terms: 'https://calio.me/web/terminos-de-uso/',
    //     privacy: 'https://calio.me/web/seguridad-y-privacidad/',
    //   };
    default:
      return {
        terms: '/static/legal/terms.html',
        privacy: '/static/legal/privacy.html',
      };
  }
}
