import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackBarComponent } from '../components/error-snack-bar/error-snack-bar.component';
import {
  isErrorSnackbarData,
  isNormalSnackbarData,
  SnackBarData,
} from '../models';
import { NormalSnackBarComponent } from '../components/normal-snack-bar/normal-snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private matSnackBar: MatSnackBar) {}

  showSnackBar(snackBarData: SnackBarData): void {
    if (isErrorSnackbarData(snackBarData)) {
      this.matSnackBar.openFromComponent(ErrorSnackBarComponent, {
        data: {
          messageKey: snackBarData.message,
          params: snackBarData.params ?? [],
        },
        panelClass: ['qt-error'],
      });
    } else if (isNormalSnackbarData(snackBarData)) {
      this.matSnackBar.openFromComponent(NormalSnackBarComponent, {
        data: {
          messageKey: snackBarData.message,
          params: snackBarData.params ?? [],
        },
      });
    }
  }
}
