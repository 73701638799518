import { Inject, Injectable } from '@angular/core';
import { WebSocketService } from '@qtek/standalone/core-libs/websockets-core';
import { HTTP_BASE_URL } from '@qtek/standalone/core-libs/api-core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  interval,
  map,
  mergeMap,
  Observable,
  retry,
  Subscription,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HealthcheckCoreService {
  private readonly isHealthyHttpConnectionSubject = new BehaviorSubject(true);
  private readonly isHealthyHttpConnection$ =
    this.isHealthyHttpConnectionSubject
      .asObservable()
      .pipe(distinctUntilChanged());
  private readonly isWebSocketAlive$ =
    this.webSocketService.getWebSocketIsAlive$();
  private isHealthyHttpConnectionSubscription: Subscription | null = null;
  private retryDelay = (interval: number) => {
    this.isHealthyHttpConnectionSubject.next(false);
    return timer(interval);
  };
  readonly isHealthy$ = combineLatest([
    this.isHealthyHttpConnection$,
    this.isWebSocketAlive$,
  ]).pipe(
    map(
      ([isHealthyHttpConnection, isAlive]) => isHealthyHttpConnection && isAlive
    )
  );

  constructor(
    @Inject(HTTP_BASE_URL) private httpBaseUrl: string,
    private webSocketService: WebSocketService,
    private httpClient: HttpClient
  ) {}

  registerCheckHealthSubscription(
    interval$: Observable<number>,
    endpoint$: Observable<string>
  ): void {
    this.isHealthyHttpConnectionSubscription?.unsubscribe();
    this.isHealthyHttpConnectionSubscription = combineLatest([
      interval$,
      endpoint$,
    ])
      .pipe(
        take(1),
        mergeMap(([intervalValue, endpoint]) => {
          return interval((intervalValue ?? 15) * 1000).pipe(
            switchMap(() =>
              this.httpClient.head(`${this.httpBaseUrl}${endpoint}`)
            ),
            retry({
              count: 3,
              resetOnSuccess: true,
              delay: () => this.retryDelay(intervalValue),
            })
          );
        }),
        catchError(error => {
          this.isHealthyHttpConnectionSubject.next(false);
          console.error(
            'There is a problem with connection to Backend please try refresh app, if problems persist please contact your support',
            error
          );
          return EMPTY;
        }),
        tap(() => {
          this.isHealthyHttpConnectionSubject.next(true);
        })
      )
      .subscribe();
  }
}
