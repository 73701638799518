import { NgModule } from '@angular/core';
import { LOCAL_STORAGE_KEYS_TOKEN, LocalStorageKeysTypes } from './models/local-storage-store.models';
import { provideEffects } from '@ngrx/effects';
import { LocalStorageEffects } from './store/local-storage.effects';
import { provideState } from '@ngrx/store';
import { LocalStorageManagementFeature } from './store/local-storage.reducers';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: LOCAL_STORAGE_KEYS_TOKEN,
      useValue: LocalStorageKeysTypes,
    },
    provideEffects(LocalStorageEffects),
    provideState(LocalStorageManagementFeature),
  ],
})
export class LocalStorageManagementModule {}
