import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PASSWORD_PATTERNS } from './password-patterns';

export class PasswordValidators {
  static passwordDigitRequired(
    control: AbstractControl
  ): ValidationErrors | null {
    return PASSWORD_PATTERNS.digit.test(control.value)
      ? null
      : {
          passwordDigitRequired: true,
        };
  }
  static passwordUppercaseRequired(
    control: AbstractControl
  ): ValidationErrors | null {
    return PASSWORD_PATTERNS.uppercase.test(control.value)
      ? null
      : {
          passwordUppercaseRequired: true,
        };
  }
  static passwordLowercaseRequired(
    control: AbstractControl
  ): ValidationErrors | null {
    return PASSWORD_PATTERNS.lowercase.test(control.value)
      ? null
      : {
          passwordLowercaseRequired: true,
        };
  }
  static passwordSpecialCharacterRequired(
    control: AbstractControl
  ): ValidationErrors | null {
    return PASSWORD_PATTERNS.specialCharacter.test(control.value)
      ? null
      : {
          passwordSpecialCharacterRequired: true,
        };
  }

  static confirmedPasswordValidator(
    controlName: string,
    matchingControlName: string
  ) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedPasswordValidator']
      ) {
        return null;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedPasswordValidator: true });
        return { ...formGroup.errors, confirmedPasswordValidator: true };
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }
}
