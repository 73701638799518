export const lightTheme = 'light';
export const lightThemeClass = '';
export const darkTheme = 'dark';
export const darkThemeClass = 'darkTheme';
export const allowedThemeValues = [lightTheme, darkTheme];
export const THEME_MANAGEMENT_KEY = 'ThemeManagementKey';

export type ThemeManagementState = { uiTheme: string | null; error: Error | null };

export const initialThemeManagementState: ThemeManagementState = {
  uiTheme: 'light',
  error: null,
};
