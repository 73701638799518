<mat-dialog-content>
  <div class="template-container">
    <form novalidate class="mfa-code-container" [formGroup]="mfaForm">
      <div class="mfa-code-hint">
        <em>{{ 'GNR_CONFIRM_WITH_MFA' | translate }}</em>
      </div>

      <mat-form-field>
        <mat-label>{{ 'GNR_MFA_CODE_PLACEHOLDER' | translate }}</mat-label>
        <input
          matInput
          qtAutoFocus
          autocapitalize="none"
          type="email"
          formControlName="mfaCode" />
        <mat-error *ngIf="mfaForm.controls.mfaCode.errors?.['required']">
          {{ 'ERR_FIELD_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="saveMachine">
        <span>{{ 'CMD_REM_ON_DIV' | translate }}</span>
      </mat-checkbox>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="template-container">
    <button
      mat-raised-button
      class="mfa-code-submit-buttons"
      color="accent"
      type="button"
      [qtSubmitIfValid]="mfaForm"
      (qtSubmitIfValid)="handleSubmitMfaCode()">
      {{ 'CMD_SIGNIN' | translate }}
    </button>
  </div>
</mat-dialog-actions>
