import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiCoreService } from '@qtek/standalone/core-libs/api-core';
import { ThemeManagementActions } from './theme-management.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { AuthUserActions } from '@qtek/standalone/libs/auth-user';
import { LocalStorageActions } from '@qtek/standalone/core-libs/local-storage-management';

@Injectable()
export class ThemeManagementEffects {
  setTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeManagementActions.setTheme),
      switchMap(({ theme }) =>
        this.apiCoreService.postUserData({ theme }).pipe(
          map(() => ThemeManagementActions.setThemeSuccess({ theme })),
          catchError(() => of(ThemeManagementActions.setThemeFailure()))
        )
      )
    )
  );

  setThemeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeManagementActions.setThemeSuccess),
      map(() => AuthUserActions.getUser())
    )
  );

  setThemeSuccessSaveToLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeManagementActions.setThemeSuccess),
      map(({ theme }) =>
        LocalStorageActions.saveToLocalStorageByKey({
          key: 'uiTheme',
          value: theme,
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiCoreService: ApiCoreService
  ) {}
}
