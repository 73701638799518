import { Injectable } from '@angular/core';
import {
  ApiCoreService,
  BEResponse,
  MetaResponse,
} from '@qtek/standalone/core-libs/api-core';
import { bufferTime, EMPTY, mergeMap, Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class MetaCoreService {
  private metaDataSubject = new Subject<string>();
  private metaDataSuccessSubject = new Subject<BEResponse<MetaResponse>>();

  metaData$ = this.metaDataSubject.asObservable().pipe(
    bufferTime(10),
    mergeMap(bufferedParams => {
      if (bufferedParams.length === 0) {
        return EMPTY;
      }
      const params = new HttpParams().append(
        'a',
        bufferedParams
          .reduce((prevParams, param) => {
            if (prevParams.includes(param)) {
              return prevParams;
            }

            return [...prevParams, param];
          }, [])
          .join('-')
      );
      return this.apiCoreService.getMetaData<MetaResponse>(params);
    })
  );

  metaDataSuccess$ = this.metaDataSuccessSubject.asObservable();

  metaDataSubscription$ = this.metaData$
    .pipe(takeUntilDestroyed())
    .subscribe(response => this.metaDataSuccessSubject.next(response));

  constructor(private apiCoreService: ApiCoreService) {}

  requestMetaData(params: string[]): Observable<BEResponse<MetaResponse>> {
    params.forEach(param => {
      this.metaDataSubject.next(param);
    });

    return this.metaDataSuccess$;
  }
}
