import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalStorageActions } from './local-storage.actions';
import { catchError, map, of } from 'rxjs';
import { isLocalStorageKey, LOCAL_STORAGE_KEYS_TOKEN } from '../models/local-storage-store.models';

@Injectable()
export class LocalStorageEffects {
  refreshLocalStorageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalStorageActions.refreshLocalStorage),
      map(() => {
        const records = this.localStorageKeys.reduce((prevRecords, key) => {
          if (isLocalStorageKey(this.localStorageKeys)(key)) {
            return [...prevRecords, { key, value: localStorage.getItem(key) }];
          }

          return prevRecords;
        }, []);

        return LocalStorageActions.refreshLocalStorageSuccess({ records });
      }),
      catchError((error: Error) => {
        return of(LocalStorageActions.accessError(error));
      })
    )
  );

  refreshLocalStorageByKeyEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalStorageActions.refreshLocalStorageByKey),
      map(({ key }) => {
        if (isLocalStorageKey(this.localStorageKeys)(key)) {
          return LocalStorageActions.refreshLocalStorageSuccess({
            records: [{ key, value: localStorage.getItem(key) }],
          });
        }
        return LocalStorageActions.refreshLocalStorageSuccess({ records: [] });
      }),
      catchError((error: Error) => {
        return of(LocalStorageActions.accessError(error));
      })
    )
  );

  saveToLocalStorageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalStorageActions.saveToLocalStorage),
      map(({ records }) => {
        const filteredRecord = records.filter((record) => isLocalStorageKey(this.localStorageKeys)(record.key));

        filteredRecord.forEach((record) => localStorage.setItem(record.key, record.value));

        return LocalStorageActions.refreshLocalStorageSuccess({ records: filteredRecord });
      }),
      catchError((error: Error) => {
        return of(LocalStorageActions.accessError(error));
      })
    )
  );

  saveToLocalStorageByKeyEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalStorageActions.saveToLocalStorageByKey),
      map(({ key, value }) => {
        if (isLocalStorageKey(this.localStorageKeys)(key)) {
          localStorage.setItem(key, value);

          return LocalStorageActions.refreshLocalStorageSuccess({ records: [{ key, value }] });
        }

        return LocalStorageActions.refreshLocalStorageSuccess({ records: [] });
      }),
      catchError((error: Error) => {
        return of(LocalStorageActions.accessError(error));
      })
    )
  );

  deleteLocalStorageByKeyEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalStorageActions.deleteLocalStorageByKey),
      map(({ key }) => {
        if (isLocalStorageKey(this.localStorageKeys)(key)) {
          localStorage.removeItem(key);

          return LocalStorageActions.clearLocalStorageSuccess({ keys: [key] });
        }
        return LocalStorageActions.clearLocalStorageSuccess({ keys: [] });
      }),
      catchError((error: Error) => {
        return of(LocalStorageActions.accessError(error));
      })
    )
  );

  clearLocalStorageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalStorageActions.clearLocalStorage),
      map(() => {
        this.localStorageKeys.forEach((key) => localStorage.removeItem(key));

        return LocalStorageActions.clearLocalStorageSuccess({ keys: this.localStorageKeys });
      }),
      catchError((error: Error) => {
        return of(LocalStorageActions.accessError(error));
      })
    )
  );

  constructor(private actions$: Actions, @Inject(LOCAL_STORAGE_KEYS_TOKEN) private localStorageKeys: string[]) {}
}
