import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslationCoreActions } from './translation-core.actions';
import { bufferTime, catchError, filter, map, of, tap } from 'rxjs';
import { TranslationCoreService } from '../services/translation-core.service';
import { passWhenAlive } from '@qtek/standalone/core-libs/websockets-core';
import { Language } from '@qtek/standalone/shared/models';

@Injectable()
export class TranslationCoreEffects {
  getTranslations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TranslationCoreActions.getTranslations),
        passWhenAlive(this.translationCoreService.webSocketIsAlive$),
        bufferTime(0),
        filter(translations => translations.length > 0),
        map((translations: { keys: string[]; lang?: Language }[]) => {
          this.translationCoreService.fetchTranslations(translations);
        })
      ),
    { dispatch: false }
  );

  getTranslationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TranslationCoreActions.getTranslationsSuccess),
      map(() => {
        return TranslationCoreActions.saveTranslationsToCache();
      })
    )
  );

  saveTranslationsToCache$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TranslationCoreActions.saveTranslationsToCache),
        tap(() => this.translationCoreService.saveTranslationToCache()),
        catchError(error =>
          of(TranslationCoreActions.saveTranslationsToCacheError({ error }))
        )
      ),
    { dispatch: false }
  );

  setLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TranslationCoreActions.setLanguage),
      map(() => TranslationCoreActions.saveTranslationsToCache())
    )
  );

  constructor(
    private actions$: Actions,
    private translationCoreService: TranslationCoreService
  ) {}
}
