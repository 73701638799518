import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[qtAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements AfterViewInit, OnDestroy {
  @Input('qtAutoFocusTrigger$') trigger$: Observable<void> | undefined;

  destroySubject = new Subject<void>();
  destroy$ = this.destroySubject.asObservable();

  autoFocusSubject = new Subject<void>();
  autoFocusSubscription = this.autoFocusSubject
    .asObservable()
    .pipe(
      tap(() => {
        setTimeout(() => this.elementRef.nativeElement.focus());
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.autoFocusSubject.next();

    if (this.trigger$) {
      this.trigger$
        .pipe(
          tap(() => {
            this.autoFocusSubject.next();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
