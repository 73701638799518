import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorSnackBarComponent } from './components/error-snack-bar/error-snack-bar.component';
import { TranslationCoreModule } from '@qtek/standalone/libs/translation-core';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { NormalSnackBarComponent } from './components/normal-snack-bar/normal-snack-bar.component';

@NgModule({
  imports: [CommonModule, TranslationCoreModule, MatSnackBarModule],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
  ],
  declarations: [ErrorSnackBarComponent, NormalSnackBarComponent],
})
export class SnackBarCoreModule {}
