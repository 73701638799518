import { createFeature, createReducer, on } from '@ngrx/store';
import {
  TRANSLATION_CORE_KEY,
  translationCoreInitialState,
} from '../models/translation-core.model';
import { TranslationCoreActions } from './translation-core.actions';
import { Language } from '@qtek/standalone/shared/models';

export const TranslationCoreReducer = createReducer(
  translationCoreInitialState,
  on(
    TranslationCoreActions.getTranslationsSuccess,
    (state, { translations, lang }) => {
      const translationLang = (lang as Language) ?? state.lang;
      return {
        ...state,
        translations: {
          ...state.translations,
          [translationLang]: {
            ...(state.translations && state.translations[translationLang]
              ? state.translations[translationLang]
              : {}),
            ...translations.reduce((prev, currentItm) => {
              return {
                ...prev,
                [currentItm.i]: currentItm.t ?? null,
              };
            }, {}),
          },
        },
      };
    }
  ),
  on(
    TranslationCoreActions.getCachedTranslationsSuccess,
    (state, { translations }) => {
      return {
        ...state,
        translations,
      };
    }
  ),
  on(TranslationCoreActions.getTranslationsError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(TranslationCoreActions.getCachedTranslationsError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(
    TranslationCoreActions.saveTranslationsToCacheError,
    (state, { error }) => {
      return {
        ...state,
        error,
      };
    }
  ),
  on(TranslationCoreActions.setLanguage, (state, { language }) => {
    return {
      ...state,
      lang: language,
    };
  })
);

export const TranslationCoreFeature = createFeature({
  name: TRANSLATION_CORE_KEY,
  reducer: TranslationCoreReducer,
});
