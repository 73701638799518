import { createFeature, createReducer, on } from '@ngrx/store';
import { initialThemeManagementState, THEME_MANAGEMENT_KEY } from '../models/theme-management.model';
import { ThemeManagementActions } from './theme-management.actions';

export const themeManagementReducer = createReducer(
  initialThemeManagementState,
  on(ThemeManagementActions.setThemeSuccess, (state, { theme }) => {
    return {
      ...state,
      uiTheme: theme,
    };
  }),
  on(ThemeManagementActions.synchronizeTheme, (state, { theme }) => {
    return {
      ...state,
      uiTheme: theme,
    };
  })
);

export const ThemeManagementFeature = createFeature({
  name: THEME_MANAGEMENT_KEY,
  reducer: themeManagementReducer,
});
