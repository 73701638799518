/**
 * Returns user's base 64 encoded gmt offset
 *
 */
export function getGMTOffset(): string {
  const offset = new Date().getTimezoneOffset() / 60;
  return btoa(
    (offset < 0 ? '-' : '') +
      (Math.abs(offset) < 10
        ? '0' + Math.floor(Math.abs(offset)).toString()
        : Math.floor(Math.abs(offset)).toString()) +
      ':' +
      (Math.abs(offset) - Math.floor(Math.abs(offset)) !== 0 ? '30' : '00')
  );
}
