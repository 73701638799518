import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MetaCoreService } from '../services/';
import { MetaCoreActions } from './meta-core.actions';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable()
export class MetaCoreEffects {
  getMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaCoreActions.loadMeta),
      mergeMap(({ params }) => this.metaCoreService.requestMetaData(params)),
      map((response) => MetaCoreActions.loadMetaSuccess({ meta: response.res })),
      catchError((error) => of(MetaCoreActions.loadMetaError({ error })))
    )
  );

  getMetaTimezones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaCoreActions.loadMetaTimezones),
      map(() => MetaCoreActions.loadMeta({ params: ['tzs'] }))
    )
  );

  getMetaRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaCoreActions.loadMetaRoles),
      map(() => MetaCoreActions.loadMeta({ params: ['ptlRoleGroups'] }))
    )
  );

  getMetaLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaCoreActions.loadMetaLanguages),
      map(() => MetaCoreActions.loadMeta({ params: ['lngs'] }))
    )
  );

  getMetaTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MetaCoreActions.loadMetaGuiDomain,
        MetaCoreActions.loadMetaGuiOnlineBookDomain,
        MetaCoreActions.loadMetaMobileTitle,
        MetaCoreActions.loadMetaGuiShrDomain,
        MetaCoreActions.loadMetaAuthGuiDomain,
        MetaCoreActions.loadMetaDocGuiDomain
      ),
      map(() => MetaCoreActions.loadMeta({ params: ['ptl'] }))
    )
  );

  getMetaPhones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaCoreActions.loadMetaPhonesCountryCodes),
      map(() => MetaCoreActions.loadMeta({ params: ['phns'] }))
    )
  );

  getMetaWebsocketPingPongTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaCoreActions.loadMetaWebsocket),
      map(() => MetaCoreActions.loadMeta({ params: ['wsPingPong'] }))
    )
  );

  getColors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaCoreActions.loadMetaColors),
      map(() => MetaCoreActions.loadMeta({ params: ['colors'] }))
    )
  );

  constructor(private actions$: Actions, private metaCoreService: MetaCoreService) {}
}
