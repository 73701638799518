import { Version } from '@angular/core';

export interface Bundle {
  /** Timestamp when bundle was created. */
  readonly date: number;

  /** commit sha of HEAD when bundle was created. */
  readonly commitSha: string;

  /** Latest stable version of `qt-app-gui`. */
  readonly version: Version;
}

export interface ServerConfig {
  /**
   * Protocol that server uses
   *
   * @type {('http' | 'https' | 'ws' | 'wss')}
   * @memberof ServerConfig
   */
  protocol: 'http' | 'https' | 'ws' | 'wss';

  /**
   * Hostname of the server
   *
   * @type {string}
   * @memberof ServerConfig
   */
  hostname: string;

  /**
   * Optional port number
   *
   * @type {number}
   * @memberof ServerConfig
   */
  port?: number;

  /**
   * Optional path
   *
   * @type {string}
   * @memberof ServerConfig
   */
  path?: string;
}

export interface Environment {
  /**
   * Determines if bundle will be production ready
   *
   * @type {boolean}
   * @memberof Environment
   */
  production: boolean;

  /**
   * Title of environment
   *
   * @type {string}
   * @memberof Environment
   */
  title: string;

  /**
   * Configuration of ReST server
   *
   * @type {ServerConfig}
   * @memberof Environment
   */
  rest: ServerConfig;

  /**
   * Configuration of Webscoket server
   *
   * @type {ServerConfig}
   * @memberof Environment
   */
  websocket?: ServerConfig;

  /**
   * App name
   *
   */
  appName?: string;

  /**
   * Google tag manager tracking id
   *
   */
  gtagId?: string;

  /**
   * Facebook Pixel tracking id
   *
   * @type {string}
   * @memberof Environment
   */
  trackingFbId?: string;

  debugTranslations?: boolean;
}
