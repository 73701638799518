import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApiCoreService,
  BEResponse,
  BEStatus,
} from '@qtek/standalone/core-libs/api-core';
import { isNonNullable, isNullable } from '@qtek/standalone/shared/utils';
import { catchError, concatMap, EMPTY, map, of, switchMap } from 'rxjs';
import { MfaLoginActions } from './mfa-login.actions';

@Injectable()
export class MfaLoginEffects {
  standardLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfaLoginActions.standardLogin),
      switchMap(({ email, passwd }) =>
        this.apiCoreService.login({ email, passwd })
      ),
      map(response => {
        if (isNullable(response.sts)) {
          return MfaLoginActions.standardLoginSuccess();
        }

        if (response.sts?.sts === 'MFA') {
          return MfaLoginActions.requiredMfa();
        }

        const { message } = this.getErrorMessage(response.sts);

        return MfaLoginActions.setValidationError({ message: message });
      }),
      catchError(({ sts }: BEResponse<unknown>) => {
        if (isNonNullable(sts)) {
          const { message, params } = this.getErrorMessage(sts);

          return of(
            MfaLoginActions.setSnackBarErrorMessage({ message, params })
          );
        }

        return EMPTY;
      })
    )
  );

  standardRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfaLoginActions.standardRegister),
      switchMap(({ email, passwd, lng, gdprFlag, tkn }) =>
        this.apiCoreService
          .register<unknown>({ email, passwd, lng, gdprFlg: gdprFlag, tkn })
          .pipe(concatMap(() => this.apiCoreService.login({ email, passwd })))
      ),
      map(response => {
        if (isNullable(response.sts)) {
          return MfaLoginActions.standardLoginSuccess();
        }

        if (response.sts?.sts === 'MFA') {
          return MfaLoginActions.requiredMfa();
        }

        const { message } = this.getErrorMessage(response.sts);

        return MfaLoginActions.setSnackBarErrorMessage({ message: message });
      }),
      catchError(({ sts }: BEResponse<unknown>) => {
        if (isNonNullable(sts)) {
          const { message, params } = this.getErrorMessage(sts);

          return of(
            MfaLoginActions.setSnackBarErrorMessage({ message, params })
          );
        }

        return EMPTY;
      })
    )
  );

  mfaLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfaLoginActions.mfaLogin),
      switchMap(({ email, passwd, mfa_code, saveDevice }) =>
        this.apiCoreService.login({
          email,
          passwd,
          mfa_code,
          mfa_trust: saveDevice,
        })
      ),
      map(response => {
        if (isNullable(response.sts)) {
          return MfaLoginActions.mfaLoginSuccess();
        }

        const { message, params } = this.getErrorMessage(response.sts);

        return MfaLoginActions.setSnackBarErrorMessage({ message, params });
      }),
      catchError(({ sts }: BEResponse<unknown>) => {
        if (isNonNullable(sts)) {
          const { message, params } = this.getErrorMessage(sts);

          return of(
            MfaLoginActions.setSnackBarErrorMessage({ message, params })
          );
        }

        return EMPTY;
      })
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfaLoginActions.resetPassword),
      switchMap(({ email }) =>
        this.apiCoreService.resetPassword({ email }).pipe(
          map(response => {
            const sts = response?.sts;
            if (!sts || sts.sts !== 'ERR') {
              return MfaLoginActions.setSnackBarNormalMessage({
                message: 'OK_USR_PASS_RES_SENT',
              });
            }
            const { message, params } = this.getErrorMessage(sts);

            return MfaLoginActions.setSnackBarErrorMessage({ message, params });
          }),
          catchError(({ sts }: BEResponse<unknown>) => {
            if (isNonNullable(sts)) {
              const { message, params } = this.getErrorMessage(sts);

              return of(
                MfaLoginActions.setSnackBarErrorMessage({ message, params })
              );
            }

            return EMPTY;
          })
        )
      )
    )
  );

  resetPasswordConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfaLoginActions.resetPasswordConfirm),
      switchMap(({ pass1, pass2, email, rstToken }) =>
        this.apiCoreService
          .resetPasswordConfirm({ pass1, pass2, email, rstToken })
          .pipe(
            map(response => {
              const sts = response?.sts;
              if (!sts || sts.sts !== 'ERR') {
                return MfaLoginActions.setSnackBarNormalMessage({
                  message: 'OK_USR_PASS_CHANGED',
                });
              }

              const { message, params } = this.getErrorMessage(sts);

              return MfaLoginActions.setSnackBarErrorMessage({
                message,
                params,
              });
            }),
            catchError(({ sts }: BEResponse<unknown>) => {
              if (isNonNullable(sts)) {
                const { message, params } = this.getErrorMessage(sts);

                return of(
                  MfaLoginActions.setSnackBarErrorMessage({ message, params })
                );
              }

              return EMPTY;
            })
          )
      )
    )
  );

  successfulLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MfaLoginActions.mfaLoginSuccess,
        MfaLoginActions.standardLoginSuccess
      ),
      map(() => MfaLoginActions.redirectApp())
    )
  );

  constructor(
    private actions$: Actions,
    private apiCoreService: ApiCoreService
  ) {}

  private getErrorMessage(sts: BEStatus): {
    message: string;
    params?: string[];
    details?: string;
  } {
    const errors = Object.keys(sts && sts.attrs ? sts.attrs : {}).map(
      key => sts.attrs[key]
    );
    const main = errors.find(error => error.main) || {
      msg: 'ERR_DEFAULT_MSG',
      prms: null as null,
    };
    const details = errors.filter(error => !error.main);

    return {
      message: main.msg,
      params: main.prms ?? null,
      details: details?.[0]?.msg,
    };
  }
}
