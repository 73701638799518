<form novalidate class="login-password__container" [formGroup]="form">
  <div class="login-password__login-hint">
    {{ 'GNR_ENTER_PASSWORD_FOR_EMAIL' | translate: [email] }}

    <mat-error *ngIf="validationError?.errorTranslationKey">
      {{ validationError?.errorTranslationKey | translate }}
    </mat-error>
  </div>

  <mat-form-field>
    <mat-label>{{ 'GNR_PASS_FOR' | translate: [email] }}</mat-label>
    <input
      matInput
      qtAutoFocus
      autocapitalize="none"
      [type]="showPassword ? 'text' : 'password'"
      formControlName="password"
      [qtAutoFocusTrigger$]="mfaDialogDestroy$" />
    <button mat-icon-button matSuffix (click)="toggleShowPassword()">
      <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>
    <mat-error *ngIf="form.controls.password.errors?.['required']">
      {{ 'ERR_FIELD_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>

  <button
    qtDisableAfterClick
    mat-raised-button
    color="accent"
    type="button"
    [qtSubmitIfValid]="form"
    [qtSubmitOnEnter]="(showDialog$ | async) === false"
    (qtSubmitIfValid)="handleSubmit()">
    {{ 'CMD_SIGNIN' | translate }}
  </button>

  <span class="login-password__lost-password-container">
    <button
      mat-button
      class="mat-subtitle-2 login-password__link-button"
      color="link"
      type="button"
      (click)="handleLostPassword()">
      {{ 'GNR_PSSWD_LOST' | translate }}
    </button>

    <button
      mat-button
      class="mat-subtitle-2 login-password__link-button"
      color="link"
      type="button"
      (click)="handleBackButton()">
      {{ 'GNR_CHANGE_USER' | translate }}
    </button>
  </span>
</form>
