export interface MfaLoginState {
  email: string | null;
  emailIsEditable: boolean;
  passwd: string | null;
  mfa_code: string | null;
  fn: string | null;
  saveDevice: boolean;
  requireMfaCode: boolean;
  errorMessage: string | null;
  errorMessageParams: string[] | null;
  normalMessage: string | null;
  normalMessageParams: string[] | null;
  returnUrl: string | null;
  requestedRedirect: boolean | null;
  validationErrorMessage: string | null;
  validationErrorMessageParams: string[] | null;
}

export const mfaLoginInitialState: MfaLoginState = {
  email: null,
  emailIsEditable: true,
  mfa_code: null,
  passwd: null,
  saveDevice: false,
  requireMfaCode: false,
  errorMessage: null,
  errorMessageParams: null,
  normalMessage: null,
  normalMessageParams: null,
  returnUrl: null,
  requestedRedirect: null,
  fn: null,
  validationErrorMessageParams: null,
  validationErrorMessage: null,
};

export const LOGIN_CORE_KEY = 'MfaLoginCoreKey';
