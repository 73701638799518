<form novalidate class="login-reset-password__container" [formGroup]="form">
  <div class="login-reset-password__login-hint">
    {{ 'GNR_RESET_PASSWORD_FOR' | translate }}
  </div>

  <mat-form-field>
    <mat-label>{{ 'GNR_EMAIL_ADR' | translate }}</mat-label>
    <input
      matInput
      qtAutoFocus
      autocapitalize="none"
      type="email"
      formControlName="email"
      [placeholder]="'GNR_EMAIL_ADR' | translate" />
    <mat-error *ngIf="form.controls.email.errors?.['required']">
      {{ 'ERR_FIELD_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.email.errors?.['email']">
      {{ 'ERR_WRONG_EMAIL' | translate }}
    </mat-error>
  </mat-form-field>

  <button
    qtDisableAfterClick
    mat-raised-button
    color="accent"
    type="button"
    [qtSubmitIfValid]="form"
    (qtSubmitIfValid)="handleSubmit()">
    {{ 'GNR_CONTINUE' | translate }}
  </button>

  <span class="login-reset-password__back-container">
    <button
      mat-button
      class="mat-subtitle-2 login-reset-password__back-button"
      color="link"
      type="button"
      (click)="handleBackButton()">
      {{ 'CMD_BACK' | translate }}
    </button>
  </span>
</form>
