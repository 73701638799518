import { createFeature, createReducer, on } from "@ngrx/store";
import {
  LOGIN_CORE_KEY,
  mfaLoginInitialState,
} from "../models/mfa-login-state";
import { MfaLoginActions } from "./mfa-login.actions";

export const MfaLoginReducer = createReducer(
  mfaLoginInitialState,
  on(MfaLoginActions.setEmail, (state, { email }) => {
    return {
      ...state,
      email,
    };
  }),
  on(MfaLoginActions.setPassword, (state, { passwd }) => {
    return {
      ...state,
      passwd,
    };
  }),
  on(MfaLoginActions.setMfaCode, (state, { saveDevice, mfaCode }) => {
    return {
      ...state,
      saveDevice,
      mfa_code: mfaCode,
    };
  }),
  on(MfaLoginActions.requiredMfa, (state) => {
    return {
      ...state,
      requireMfaCode: true,
    };
  }),

  on(MfaLoginActions.standardLogin, (state) => {
    return {
      ...state,
      requireMfaCode: false,
      errorMessage: null,
      errorMessageParams: null,
      normalMessage: null,
      normalMessageParams: null,
      validationErrorMessage: null,
    };
  }),
  on(MfaLoginActions.mfaLogin, (state) => {
    return {
      ...state,
      requireMfaCode: false,
      errorMessage: null,
      errorMessageParams: null,
      normalMessage: null,
      normalMessageParams: null,
      validationErrorMessage: null,
    };
  }),
  on(MfaLoginActions.resetSnackBarMessages, (state) => {
    return {
      ...state,
      errorMessage: null,
      errorMessageParams: null,
      normalMessage: null,
      normalMessageParams: null,
    };
  }),
  on(MfaLoginActions.setSnackBarErrorMessage, (state, { message, params }) => {
    return {
      ...state,
      errorMessage: message,
      errorMessageParams: params ?? null,
      normalMessage: null,
      normalMessageParams: null,
    };
  }),
  on(MfaLoginActions.setSnackBarNormalMessage, (state, { message, params }) => {
    return {
      ...state,
      errorMessage: null,
      errorMessageParams: null,
      normalMessage: message,
      normalMessageParams: params ?? null,
    };
  }),
  on(MfaLoginActions.setEmailEditable, (state, { isEditable }) => {
    return {
      ...state,
      emailIsEditable: isEditable,
    };
  }),
  on(MfaLoginActions.setReturnUrl, (state, { returnUrl }) => {
    return {
      ...state,
      returnUrl,
    };
  }),
  on(MfaLoginActions.setFirstName, (state, { fn }) => {
    return {
      ...state,
      fn,
    };
  }),
  on(MfaLoginActions.redirectApp, (state) => {
    return {
      ...state,
      requestedRedirect: true,
    };
  }),
  on(MfaLoginActions.setValidationError, (state, { message, params }) => {
    return {
      ...state,
      validationErrorMessage: message,
      validationErrorMessageParams: params,
    };
  })
);

export const MfaLoginFeature = createFeature({
  name: LOGIN_CORE_KEY,
  reducer: MfaLoginReducer,
});
