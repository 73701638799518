import { Environment } from '@env';

export const environment: Environment = {
  production: false,
  title: 'DEV',
  rest: {
    protocol: 'https',
    hostname: 'api.arianta.wi.zut.edu.pl',
    path: '',
  },
  websocket: {
    protocol: 'wss',
    hostname: 'wsapi.arianta.wi.zut.edu.pl',
    path: '',
  },
  debugTranslations: true,
};
