<button
  (click)="login()"
  class="button"
  mat-stroked-button
>
  <mat-icon
    *ngIf="iconName"
    class="icon"
    [svgIcon]="iconName"
  ></mat-icon>
  <span *ngIf="buttonText">
    {{ buttonText | translate}}
  </span>
</button>