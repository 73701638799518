import { Inject, Injectable, Optional } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { combineLatest, tap } from 'rxjs';
import {
  darkTheme,
  darkThemeClass,
  lightTheme,
} from '../models/theme-management.model';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { ThemeManagementFeature } from '../store/theme-management.reducers';
import { LocalStorageManagementFeature } from '@qtek/standalone/core-libs/local-storage-management';
import { AuthUserFeature } from '@qtek/standalone/libs/auth-user';
import { ThemeManagementActions } from '../store/theme-management.actions';

@Injectable({
  providedIn: 'root',
})
export class ThemeManagementService {
  private themeSubscription = this.store
    .select(ThemeManagementFeature.selectUiTheme)
    .pipe(
      tap(theme => {
        if (theme === darkTheme) {
          this.document.body.classList.add(darkThemeClass);
          this.overlayContainer
            ?.getContainerElement()
            .classList.add(darkThemeClass);
        }

        if (theme === lightTheme) {
          this.document.body.classList.remove(darkThemeClass);
          this.overlayContainer
            ?.getContainerElement()
            .classList.remove(darkThemeClass);
        }
      })
    )
    .subscribe();

  private themeSources = combineLatest([
    this.store.select(AuthUserFeature.getTheme),
    this.store.select(LocalStorageManagementFeature.selectUiTheme),
    this.store.select(ThemeManagementFeature.selectUiTheme),
  ])
    .pipe(
      tap(([beTheme, lsTheme, theme]) => {
        if (!!beTheme && theme !== beTheme) {
          this.store.dispatch(
            ThemeManagementActions.synchronizeTheme({ theme: beTheme })
          );
        } else if (!beTheme && !!lsTheme && theme !== lsTheme) {
          this.store.dispatch(
            ThemeManagementActions.synchronizeTheme({ theme: lsTheme })
          );
        }
      })
    )
    .subscribe();

  constructor(
    @Optional() private overlayContainer: OverlayContainer,
    @Inject(DOCUMENT) private document: Document,
    private store: Store
  ) {}
}
