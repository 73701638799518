import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideState } from '@ngrx/store';
import { AuthUserFeature } from './store/auth-user.reducer';
import { provideEffects } from '@ngrx/effects';
import { AuthUserEffects } from './store/auth-user.effects';
import { ApiCoreModule } from '@qtek/standalone/core-libs/api-core';

@NgModule({
  imports: [CommonModule, ApiCoreModule],
  declarations: [],
  providers: [provideState(AuthUserFeature), provideEffects(AuthUserEffects)],
})
export class AuthUserModule {}
