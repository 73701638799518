import { filter, mergeMap, Observable, take } from 'rxjs';
import { Language } from '@qtek/standalone/shared/models';
import { InjectionToken } from '@angular/core';

export const DebugTranslationsDI = new InjectionToken<boolean>(
  'debugTranslationsDI'
);
export const LanguagesArray: Language[] = ['pl_PL', 'en_US', 'es_US'];
export const TRANSLATION_CORE_KEY = 'TranslationCoreKey';
export interface Translation {
  i: string;
  t: string;
}

export interface TranslationCoreState {
  lang: Language;
  version: string;
  translations: Record<Language, Record<string, string>>;
  error: Error | null;
}

export const translationCoreInitialState: TranslationCoreState = {
  lang: 'en_US',
  version: '',
  translations: {
    en_US: {},
    pl_PL: {},
    es_US: {},
  },
  error: null,
};

export function passWhenLocalStorageLoaded<T>(isLoaded$: Observable<boolean>) {
  return function <T>(source: Observable<T>) {
    return source.pipe(
      mergeMap(() => isLoaded$),
      filter(isLoaded => !!isLoaded),
      take(1),
      mergeMap(() => source)
    );
  };
}
